<script lang="ts" setup>
import { computed, useTemplateRef } from "vue";
import { __, Icons, OptionButtonAction, Shortcut, WuxOptionButton } from "@ui/components";
import { LOGOUT_PATH } from "../auth/paths";
import { useUserStore } from "../auth/user";
import { WaWiLanguage } from "../i18n/language/language";
import { WaWiModule } from "../module/module";
import AboutContent from "./AboutContent.vue";
import { Dialog } from "./Dialog";
import { DirtyGuard } from "./DirtyGuard";
import { GuidedTour, useGuidedTourStore } from "./GuidedTour";
import { ShortcutInfo } from "./shortcuts";

const props = defineProps<{ shortcut: Shortcut }>();
const emit = defineEmits<{ open: [] }>();

const userStore = useUserStore();
const guidedTourStore = useGuidedTourStore();
const language = WaWiLanguage.getCurrentOption();

const logout = async () => {
    if (!(await DirtyGuard.isLogoutConfirmed())) return;
    window.location.assign(LOGOUT_PATH);
};

const goToProfilePage = (metaOrCtrl: boolean) => {
    WaWiModule.setLaunchpad({ subPath: "/user", newTab: metaOrCtrl });
};
const onAbout = () =>
    Dialog.info({
        headerMsg: __("ui.libs.about.title"),
        content: { component: AboutContent },
    });

const options = computed<OptionButtonAction[]>(() => {
    const options: OptionButtonAction[] = [
        {
            labelMsg: __("ui.libs.profile-menu.about"),
            icon: Icons.circle_information,
            onClick: () => onAbout(),
        },
    ];

    if (userStore.isLoggedIn) {
        options.push({
            label: userStore.displayName,
            icon: Icons.profile,
            onClick: (event) => goToProfilePage(!!event?.metaKey),
            description: `${userStore.email}\n${language.label}`,
        });
    }

    options.push({
        labelMsg: __("ui.libs.profile-menu.shortcuts"),
        icon: Icons.keyboard,
        onClick: () => ShortcutInfo.show(),
    });

    if (guidedTourStore.steps.length) {
        options.push({
            labelMsg: __("ui.libs.profile-menu.guided-tour-start"),
            icon: Icons.education,
            onClick: () => GuidedTour.start(),
        });
    }

    options.push({
        labelMsg: __("ui.libs.profile-menu.logout"),
        icon: Icons.logout,
        onClick: () => logout(),
    });

    return options;
});

const optionButton = useTemplateRef("optionButton");
defineExpose({ close: () => optionButton.value?.close() });
</script>

<template>
    <WuxOptionButton
        ref="optionButton"
        class="profile-menu"
        isPrimary
        isDense
        :icon="Icons.profile"
        :options
        :shortcut="props.shortcut"
        @open="emit('open')"
    />
</template>

<style lang="scss">
.profile-menu {
    .wux-option-button__button {
        border-radius: 0;
        --wux-button-bg-color: transparent;
        --wux-button-bg-hover-color: var(--wawi-color-white-overlay);
        min-height: 2.5rem;
        min-width: 2.5rem;

        &:hover {
            background-color: var(--wawi-color-white-overlay);
        }
    }

    &:focus-within {
        .wux-option-button__button {
            outline: var(--focus-visible-outline-width--regular) solid var(--wawi-color-grayscale-white);
            outline-offset: var(--focus-visible-outline-offset--regular);
        }
    }
}
</style>
